import Select from "react-select";
import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/async-creatable";
import { Form, Button, Spinner, Table } from "react-bootstrap";
import React, {useEffect, useState, useRef} from "react";
import moment from 'moment-timezone';
import { useForm, useFieldArray } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {useTranslation} from "react-i18next";
import {useAuth} from "@/services/Auth";
import SpendingCapsFields, { useSpendingCapsRules } from "@/components/SpendingCapsFields";
import ConfirmDialog from "../../../components/Modals/ConformDialog";
import {AsyncPaginate} from "react-select-async-paginate";

function FleetForm({fleet, handleSubmit: save, loading, buttonText, serverErrors}) {
    const {t, i18n} = useTranslation();
    let auth = useAuth();

    const requestStore = useRef({});
    const { current: requests } = requestStore;
    const [showConfirm, setShowConform] = useState(false);


    const formatRecord = (record) => {
        record.value = record.id;
        record.label = record.name;

        return record;
    }

    const formatVehicle = (vehicle) => {
        const  object = {...vehicle};

        object.value = vehicle.registration_number;
        object.label = vehicle.registration_number;

        return object;
    };

    const schema = yup.object().shape({
        name         : yup.string()
            .required(t('form_validation.name_is_required')),
        contact_name : yup.string()
            .required(t('form_validation.contact_name_is_required')),
        contact_email: yup.string().email()
            .required(t('form_validation.email_address_is_required')),
        company_id:  auth.user.is_platform_admin && !fleet?.company_id ? yup.string()
            .required(t('form_validation.company_is_required')) : yup.string(),

        vehicles: yup.array(),

        compliance_status: yup.string().oneOf(['Active', 'Inactive', 'Cancelled']),
        compliance_licences: yup.number()
            .transform(_ => isNaN(_) ? null : _)
            .nullable()
            .typeError(t('form_validation.is_required', { attribute: t('number_of_licences') }))
            .when(['compliance_status'], (compliance_status, schema) => {
                return compliance_status != 'Inactive' && schema.min(1, t('form_validation.min', { attribute: t('number_of_licences'), value: 1 })) || schema;
            }),
        compliance_auto_price: yup.boolean(),

        account_items: yup.array().of(
            yup.object().shape({
                name         : yup.string()
                    .required(t('form_validation.is_required', { attribute: t('title') })),
                renew_date         : yup.date()
                    .typeError(t('form_validation.is_required', { attribute: t('renew_date') }))
                    .required(t('form_validation.is_required', { attribute: t('renew_date') })),
                unit_price : yup.number()
                    .min(0.01, t('form_validation.min', { attribute: t('unit_price_gbp'), value: 0.01 }))
                    .required(t('form_validation.is_required', { attribute: t('unit_price_gbp') })),
                quantity : yup.number()
                    .min(1, t('form_validation.min', { attribute: t('quantity'), value: 1 }))
                    .required(t('form_validation.is_required', { attribute: t('quantity') })),
            }),
        ),

        addon_purchase_using_fleet_account: yup.boolean(),
        spending_caps: useSpendingCapsRules(),
    }).noUnknown(true);

    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
        setError,
        watch,
        setValue,
        getValues
    } = useForm({
        defaultValues: (_ => {
            const values = {... (fleet || {})};

            values.compliance_auto_price = values.compliance_auto_price ? 1 : 0;

            values.compliance_licences = values.compliance_licences || 0;
            values.addon_purchase_using_fleet_account = values.addon_purchase_using_fleet_account ? 1 : 0;

            if(values.account_items instanceof Object)
            for(var i in values.account_items)
            {
                values.account_items[i].renew_date = moment(values.account_items[i].renew_date).format('YYYY-MM-DD');
            }

            if(values.company)
            {
                values.selected_company = formatRecord(values.company);
            }

            if(values.vehicles)
            {
                values.selected_vehicles = values.vehicles.map(formatVehicle);
                values.vehicles = values.vehicles.map(_ => _.registration_number);
            }

            return values;
        })(),
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        return () => {
            for(var key in requests)
            {
                requests[key] && requests[key].abort && requests[key].abort();
            }
        }
    }, []);

    useEffect(() => {
        register('company_id');
        register('selected_company');
        register('vehicles');
        register('selected_vehicles');
    }, []);

    const company   = watch('selected_company');
    const company_id   = watch('company_id');
    const vehicles = watch('selected_vehicles');
    const compliance_status = watch('compliance_status');
    const compliance_licences = watch('compliance_licences');
    const compliance_auto_price = watch('compliance_auto_price');

    const { fields: account_items, append, remove, replace } = useFieldArray({
        control,
        name: 'account_items',
    });

  const loadPaginatedClient = (search, loadedOptions, { page }) => {
    requests.clients && requests?.clients?.abort && requests.clients.abort();

    return new Promise((resolve, reject) => {
      const pageSize = 15;
      requests.clients = auth.getRequest('/clients',
        {
          take: pageSize,
          skip: (page-1)*pageSize,
          search
        });

      requests.clients.then(({ data }) => {
        const hasMore = page * pageSize < data.total

        resolve({
          options: data.clients.map(formatRecord),
          hasMore,
          additional: {
            page: page + 1,
          },
        })

        resolve();
      })
        .catch(error => reject(error))
    });

  }

    const getVehicles = (search) => {
        requests.vehicles && requests?.vehicles?.abort && requests.vehicles.abort();

        let companyId = null

        if(company && company.id)
        {
          companyId = company.id
        }
        else if(company_id)
        {
          companyId = company_id
        }

        return new Promise((resolve, reject) => {
            requests.vehicles = auth.getRequest(companyId && `clients/${companyId}/vehicles` || 'vehicles', { search });

            requests.vehicles.then(response => {
                    resolve(response.data.vehicles.map(formatVehicle));
                })
                .catch(error => reject(error))
        });
    };

    const onSubmit = (values) => {
        delete values.company;
        delete values.selected_company;
        delete values.selected_vehicles;

        values.spending_caps = values.spending_caps && values.spending_caps
            .filter(_ => {
                return !((!_.cost_cap || _.cost_cap == '') && (!_.cap_period || !_.category_id));
            });

        return save(values);
    };
    const handleClose = () => {
        setShowConform(false);
    };

    const confirmClear = () => {
        setShowConform(true);
    };

    const clearVehicles = () => {
        setValue('vehicles', []);
        setValue('selected_vehicles', []);
        handleClose();
    };

    useEffect(() => {
        if(compliance_status == 'Active' && compliance_licences > 0 && compliance_auto_price > 0)
        {
            replace([
                {
                    name: 'Compliance Licence',
                    renew_date: account_items[0] && account_items[0].renew_date || moment().format('YYYY-MM-DD'),
                    unit_price: auth.compliance_licence_price,
                    quantity: compliance_licences,
                }
            ])
        }
    }, [compliance_status, compliance_licences, compliance_auto_price]);


    return (
        <>
        <ConfirmDialog
            message='conform_dialog.clear_all_selected_vehicles'
            cancel='conform_dialog.cancel'
            accept='conform_dialog.accept'
            acceptAction={clearVehicles}
            show={showConfirm}
            handleClose={handleClose}
        />
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mt-4">
                <div className="row">
                    {
                        (auth.user.is_platform_admin && !fleet?.company_id) &&
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                            <Form.Label className="my-2">
                                {t('client_name')}
                            </Form.Label>
                            <AsyncPaginate
                              className="react-select"
                              placeholder={t('client')}
                              defaultOptions={true}
                              isClearable={true}
                              value={company}
                              loadOptions={loadPaginatedClient}
                              onChange={(e) => {
                                setValue('company_id', e && e.value);
                                setValue('selected_company', e);
                              }}
                              additional={{
                                page: 1,
                              }}
                            />

                            <Form.Control.Feedback type="invalid" className={!!errors.company_id ? 'd-block' : ''}>
                                {errors.company_id && errors.company_id.message}
                            </Form.Control.Feedback>
                        </div>
                    }

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                        <Form.Label className="my-2">
                            {t('fleet_name')}
                        </Form.Label>

                        <Form.Control
                            type="text"
                            placeholder={t('fleet_name')}
                            {...register('name')}
                            isInvalid={!!errors.name}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.name && errors.name.message}
                        </Form.Control.Feedback>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                        <Form.Label className="my-2">
                            {t('contact_name')}
                        </Form.Label>

                        <Form.Control
                            type="text"
                            placeholder={t('contact_name')}
                            {...register('contact_name')}
                            isInvalid={!!errors.contact_name}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.contact_name && errors.contact_name.message}
                        </Form.Control.Feedback>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                        <Form.Label className="my-2">
                            {t('email_address')}
                        </Form.Label>

                        <Form.Control
                            type="text"
                            placeholder={t('enter_email_address')}
                            {...register('contact_email')}
                            isInvalid={!!errors.contact_email}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.contact_email && errors.contact_email.message}
                        </Form.Control.Feedback>
                    </div>
                </div>
            </div>

            <div className="form-group mt-4">
                <div className="row">
                    <div className="col-12">
                        <Form.Label className="my-2">
                            {t('drawer_link_titles.vehicles')}
                        </Form.Label>

                        <CreatableSelect
                            className="react-select"
                            isMulti
                            isClearable={true}
                            value={vehicles}
                            onChange={(e) => {
                                if( (e == null || e.length ===0) && vehicles.length !== 0 ){
                                    confirmClear();
                                }
                                else{
                                    setValue('vehicles', e.map(_ => _.value));
                                    setValue('selected_vehicles', e);
                                }
                            }}
                            placeholder={t('drawer_link_titles.vehicles')}
                            defaultOptions={true}
                            loadOptions={getVehicles}
                        />

                        <Form.Control.Feedback type="invalid" className={!!errors.vehicles ? 'd-block' : ''}>
                            {errors.vehicles && errors.vehicles.message}
                        </Form.Control.Feedback>
                    </div>
                </div>



            </div>

            <h4 className="mt-4">{t('compliance.title')}</h4>

            <div className="row">
                <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                    <Form.Label className="my-2">
                        {t('compliance.active')}
                    </Form.Label>

                    <Form.Select
                        {...register('compliance_status')}
                        isInvalid={!!errors.compliance_status}
                        disabled={!auth.user.is_platform_admin && !fleet?.compliance_auto_price}
                        >
                        {
                            ['Active', 'Cancelled'].includes(fleet?.compliance_status) && <option value="Cancelled">{t('cancel')}</option>
                        }

                        {
                            ['Inactive'].includes(fleet?.compliance_status || 'Inactive') && <option value="Inactive">{t('no')}</option>
                        }

                        <option value="Active">{t('yes')}</option>
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                        {errors.compliance_status && errors.compliance_status.message}
                    </Form.Control.Feedback>

                    {
                        compliance_status == 'Cancelled' &&
                        <Form.Text id="compliance_status_helpblock" muted>
                            { t('compliance.cancellation_info_block') }
                        </Form.Text>
                    }
                </div>

                <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                    <Form.Label className="my-2">
                        {t('number_of_licences')}
                    </Form.Label>

                    <Form.Control
                        {...register('compliance_licences')}
                        type="number"
                        isInvalid={!!errors.compliance_licences}
                        disabled={!auth.user.is_platform_admin && !fleet?.compliance_auto_price}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.compliance_licences && errors.compliance_licences.message}
                    </Form.Control.Feedback>

                    <p><small className="text-muted">{t('compliance.licences_description')}</small></p>
                </div>

                {auth.user.is_platform_admin && (
                    <>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                        <Form.Label className="my-2">
                            {t('use_standard_price')}
                        </Form.Label>

                        <Form.Select
                            {...register('compliance_auto_price')}
                            isInvalid={!!errors.compliance_auto_price}
                            >
                            <option value={1}>{t('yes')}</option>
                            <option value={0}>{t('no')}</option>

                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.compliance_auto_price && errors.compliance_auto_price.message}
                        </Form.Control.Feedback>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                        <Form.Label className="my-2">
                            &nbsp;
                        </Form.Label>

                        <span className="text-muted">{t('compliance.override_warning')}</span>
                    </div>
                    </>
                ) || ''}

            </div>

            {compliance_status != 'Inactive' && (
                <>
                <h5>{t('monthly_bill')}</h5>

                <Table>
                    <thead>
                        <tr>
                            <th>
                                {t('title')}
                            </th>

                            <th>
                                {t('renew_date')}
                            </th>

                            <th>
                                {t('unit_price_gbp')}
                            </th>


                            <th>
                                {t('quantity')}
                            </th>

                            {auth.user.is_platform_admin && compliance_auto_price < 1 && (
                                <th />
                            )}
                        </tr>
                    </thead>

                    <tbody>
                    {account_items.map((account_item, index) => (

                        <tr key={index}>
                            <td>
                                {auth.user.is_platform_admin && compliance_auto_price < 1 && (
                                    <>
                                        <Form.Control
                                            {...register(`account_items.${index}.name`)}
                                            type="text"
                                            isInvalid={!!errors.name}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.name && errors.name.message}
                                        </Form.Control.Feedback>
                                    </>
                                ) || account_item.name}
                            </td>

                            <td>
                                {auth.user.is_platform_admin && compliance_auto_price < 1 && (
                                    <>
                                        <Form.Control
                                            {...register(`account_items.${index}.renew_date`)}
                                            type="date"
                                            isInvalid={!!errors.renew_date}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.renew_date && errors.renew_date.message}
                                        </Form.Control.Feedback>
                                    </>
                                ) || moment(account_item.renew_date).format(t('formats.date'))}
                            </td>

                            <td>
                                {auth.user.is_platform_admin && compliance_auto_price < 1 && (
                                    <>
                                        <Form.Control
                                            {...register(`account_items.${index}.unit_price`)}
                                            type="number"
                                            isInvalid={!!errors.unit_price}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.unit_price && errors.unit_price.message}
                                        </Form.Control.Feedback>
                                    </>
                                ) || account_item.unit_price}
                            </td>


                            <td>
                                {auth.user.is_platform_admin && compliance_auto_price < 1 && (
                                    <>
                                        <Form.Control
                                            {...register(`account_items.${index}.quantity`)}
                                            type="number"
                                            isInvalid={!!errors.quantity}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.quantity && errors.quantity.message}
                                        </Form.Control.Feedback>
                                    </>
                                ) || account_item.quantity}
                            </td>

                            {auth.user.is_platform_admin && compliance_auto_price < 1 && (
                                <td>
                                    <Button type="button" onClick={_ => remove(index)} variant="danger">
                                        {t('remove')}
                                    </Button>
                                </td>
                            )}

                        </tr>
                    ))}
                    </tbody>
                </Table>
                            {auth.user.is_platform_admin && compliance_auto_price < 1 && (
                                    <Button type="button" onClick={_ => append({})} variant="primary" size="sm">
                                        {t('add')}
                                    </Button>
                            )}

                </>
            )}


            <h4 className="mt-4">{t('fleet_spending_caps')}</h4>

            <div>
                <label htmlFor="addon_purchase_using_fleet_account" className="my-2">{t('addon_purchase_using_fleet_account')}</label>
                <Form.Select
                    {...register('addon_purchase_using_fleet_account')}
                    isInvalid={errors.addon_purchase_using_fleet_account}
                >
                    <option value={1}>{t('yes')}</option>
                    <option value={0}>{t('no')}</option>
                </Form.Select>

                <Form.Control.Feedback type="invalid">
                    {errors.addon_purchase_using_fleet_account && errors.addon_purchase_using_fleet_account.message}
                </Form.Control.Feedback>
            </div>

            <SpendingCapsFields
                {...{
                    errors,
                    watch,
                    control,
                    register,
                    setValue,
                }}

            />

            {
                (serverErrors.length !== 0) &&
                <div className="form-group mt-4">
                    {
                        serverErrors.map((error, index) => <p className="text-danger font-weight-bold" key={index}>{t(error)}</p>)
                    }
                </div>
            }
            <div className="d-grid gap-2 mt-4">
                {!loading ?
                 <Button type="submit" variant="primary">{t(buttonText)}</Button>
                          :
                 <Button variant="primary" disabled>
                     <Spinner
                         as="span"
                         animation="border"
                         size="sm"
                         role="status"
                         aria-hidden="true"
                     />
                     <span className="mx-2">{t('please_wait')}</span>
                 </Button>
                }
            </div>
        </Form>
        </>
    )
}

export default FleetForm
