import {useTranslation} from "react-i18next";
import {useAuth} from "@/services/Auth";
import {useParams} from "react-router-dom";
import React, {useEffect, useState, useCallback} from "react";
import LoadingPage from "@/components/LoadingPage";
import * as moment from 'moment-timezone';
import {Alert, Badge, Card, Col, Form, Row} from "react-bootstrap";
import QRCode from 'qrcode';
import {Helmet} from "react-helmet";
import BookingShow from "./BookingShow";
import useMoment from "@/hooks/useMoment";

function Show({location}) {
    let auth = useAuth();
    return (
        auth.user?.is_driver && (
            <BookingDriverShow location={location}></BookingDriverShow>
        ) || (
            <BookingShow location={location}></BookingShow>
        )
    )
}

function BookingDriverShow({location}) {
    const {momentLocal} = useMoment()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const {t, i18n} = useTranslation();

    let {id} = useParams();
    let auth = useAuth();

    const [booking, setBooking] = useState(location.state && location.state.booking);
    const [restrictedCategories, setRestrictedCategories] = useState({});
    const [src, setSrc] = useState(null);

    useEffect(() => {
        if (!booking || booking.id != id) {
            auth.getRequest('/bookings/' + id)
                .then(response => {
                    setSrc(null);
                    setBooking(response.data.booking);
                    setRestrictedCategories(response.data.restricted_categories);
                })

        }
    }, [booking, id]);

    useEffect(() => {
        if (booking && !src && booking.token) {
            QRCode.toDataURL(JSON.stringify({
                booking: {
                    id: booking.id,
                    token: booking.token,
                }
            })).then(data => {
                setSrc(data);
            })
        }
    }, [booking, src])

    if (!booking || (booking.token && !src)) {
        return <LoadingPage/>;
    }

    let isAdr = false;
    let isOut = false;

    let qrBgColor = '';

    booking?.order_items?.forEach && booking?.order_items?.forEach(orderItem => {
        if (orderItem.category_id == restrictedCategories?.adr) {
            isAdr = true;
        }
        if (
            orderItem.category_id == restrictedCategories?.long_bay ||
            orderItem.category_id == restrictedCategories?.wide_bay
        ) {
            isOut = true;
        }
    });

    if (booking.instant_booking) {
        qrBgColor = '#00458c';
    }

    if (booking.prebooked) {
        qrBgColor = '#116145';
    }


    if (isOut) {
        qrBgColor = '#79ddff';
    }

    if (isAdr) {
        qrBgColor = '#921313';
    }

    const isInstant = !isAdr && !isOut && booking.instant_booking;
    const isOutNotAdr = !isAdr && isOut;

    const startBookingTime = moment(booking.from_datetime ?? booking.created_at).subtract('15', 'minutes').toISOString();
    const currentTime = moment(moment.now()).toISOString()

    return (
        <div style={{marginTop: "-1.1rem"}}>
            <Helmet>
                <title>{booking.booking_number + ''} / {t('drawer_link_titles.bookings')} - {t('app')}</title>
            </Helmet>

            {booking.token && (
                (startBookingTime <=currentTime) && (
                    <div style={{width: '100%', backgroundColor: qrBgColor, paddingTop: '1rem'}}>
                        <Card.Subtitle className="d-flex flex-direction-column justify-content-center">

                            <div className="qr-code-contend d-flex text-center flex-column">
                                <img src={src} className="rounded"/>
                            </div>
                        </Card.Subtitle>
                        <div className="d-inline-flex gap-3 my-3">
                            {!!isInstant && (
                                Array.from(Array(20).keys()).map((key) => (
                                    <p key={key} className="text-white m-0 facility-name-site-card" style={{width: '72px'}}>
                                        {t('booking.instant_booking')}
                                    </p>
                                ))
                            )}
                            {!!booking.prebooked && (
                                Array.from(Array(20).keys()).map((key) => (
                                    <div key={key} className="d-inline-flex gap-2 align-items-center">
                                        <img className="service-icon" src={`/images/facilities/prebook-transparent.svg`}/>
                                        <p className="text-white m-0 facility-name-site-card" style={{width: '62px'}}>
                                            {t('booking.auto_approval')}
                                        </p>
                                    </div>
                                ))
                            )}
                            {!!isOutNotAdr && (
                                Array.from(Array(20).keys()).map((key) => (
                                    <div key={key} className="d-inline-flex gap-1 align-items-center">
                                        <img className="service-icon" src={`/images/facilities/11-transparent.svg`}/>
                                        <p className="text-black m-0 facility-name-site-card" style={{width: '72px'}}>
                                            {t('booking.out_of_gauge')}
                                        </p>
                                    </div>
                                ))
                            )}
                            {!!isAdr && (
                                Array.from(Array(20).keys()).map((key) => (
                                    <div key={key} className="d-inline-flex gap-2 align-items-center">
                                        <img className="service-icon" src={`/images/facilities/3-transparent.svg`}/>
                                        <p className="text-white m-0 facility-name-site-card">{t('booking.adr')}</p>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                ) || (
                    <Card.Subtitle className="d-flex justify-content-between p-4 pb-2 flex-md-row flex-column"
                                   style={{width: '100%'}}>
                        <h4 className="text-primary">{t('booking_id')} - {booking.booking_number}</h4>
                        <h6>
                            <Badge pill
                                   bg='warning'
                                   text='dark'>
                                {t(`booking.qr_not_activated`)}
                            </Badge>
                        </h6>
                    </Card.Subtitle>                )
            ) || (
                <Card.Subtitle className="d-flex justify-content-between flex-md-row flex-column"
                               style={{width: '100%'}}>
                    <h4 className="text-primary">{t('booking_id')} - {booking.booking_number}</h4>
                    <h6>
                        <Badge pill
                               bg={booking.status === 'pending' ? 'warning' : (booking.status === 'disapproved' || booking.status === 'rejected') ? 'danger' : 'success'}
                               text={booking.status === 'pending' ? 'dark' : 'light'}>
                            {t(`order_status.${booking.status}`)}
                        </Badge>
                    </h6>
                </Card.Subtitle>
            )}

            <Card.Body>
                <div className="bg-gray-100 py-2">
                    <p className="m-2 text-center small">
                        {`${moment.utc(booking.from_datetime ?? booking.created_at).tz(timezone).format(t('formats.short_datetime_reverse'))} - `}
                        {(booking.duration !== 0 && booking.duration < 24) && (
                            t('n_hours', {count: booking.duration})
                        )}
                        {(booking.duration !== 0 && booking.duration >= 24) && (
                            t('n_days', {count: Math.round(booking.duration / 24)})
                        )}
                        {(booking.duration === 0 && booking.to_datetime) && (
                            moment.utc(booking.to_datetime).tz(timezone).format(t('formats.short_datetime_reverse'))
                        )}
                    </p>
                    {booking.site && (
                      <>
                        <h4 className="text-center text-primary">{booking.site.name}</h4>
                        <p className="text-center small">{ booking.site.full_address }</p>
                      </>
                    )}
                </div>

                <div className="booking-show-body">
                  {(!booking.instant_booking && booking.site.driver_message) && (

                      <Alert variant="warning">
                          <p>{booking.site.driver_message} </p>
                      </Alert>
                  )}

                  <Row className="mb-4">
                    {booking.site_comments && (
                      <Col className="col-12">
                        <Form.Group as={Row} controlId="formPlaintextCompanyName">
                          <Form.Label column className="col-12">
                            <small> {t('site_comment')}</small>
                          </Form.Label>
                          <Col className="col-12">
                            <h6>{booking.site_comments}</h6>
                          </Col>
                        </Form.Group>
                      </Col>
                    ) || ''}

                    {booking.comments && (
                      <Col className="col-12">
                        <Form.Group as={Row} controlId="formPlaintextCompanyName">
                          <Form.Label column className="col-12">
                            <small> {t('booker_comment')}</small>
                          </Form.Label>
                          <Col className="col-12">
                            <h6>{booking.comments}</h6>
                          </Col>
                        </Form.Group>
                      </Col>
                    ) || ''}

                    {booking.vehicle?.registration_number && (
                      <Col className="col-12">
                        <Form.Group as={Row} controlId="formPlaintextCompanyName">
                          <Form.Label column className="col-12">
                            <small> {t('vehicle_registration_number')}</small>
                          </Form.Label>
                          <Col className="col-12">
                            <h6>{booking.vehicle?.registration_number}</h6>
                          </Col>
                        </Form.Group>
                      </Col>
                    ) || ''}

                    {booking.from_datetime && (
                      <Col className="col-12">
                        <Form.Group as={Row} controlId="formPlaintextCompanyName">
                          <Form.Label column className="col-12">
                            <small> {t('from')}</small>
                          </Form.Label>
                          <Col className="col-12">
                            <h6>{booking.from_datetime && moment.utc(booking.from_datetime).tz(timezone).format(t('formats.datetime'))}</h6>
                          </Col>
                        </Form.Group>
                      </Col>
                    ) || ''}

                    {booking.to_datetime && (
                      <Col className="col-12">
                        <Form.Group as={Row} controlId="formPlaintextCompanyName">
                          <Form.Label column className="col-12">
                            <small> {t('to')}</small>
                          </Form.Label>
                          <Col className="col-12">
                            <h6>{booking.to_datetime && moment.utc(booking.to_datetime).tz(timezone).format(t('formats.datetime'))}</h6>
                          </Col>
                        </Form.Group>
                      </Col>
                    ) || ''}

                    {!booking.from_datetime ?
                          <Col className="col-12">
                              <Form.Group as={Row} controlId="formPlaintextCompanyName">
                                  <Form.Label column className="col-12">
                                      <small> {t('booking.datetime')}</small>
                                  </Form.Label>
                                  <Col className="col-12">
                                      <h6 className="font-weight-normal">{booking.created_at && momentLocal(booking.created_at).tz(timezone).format(t('formats.datetime'))}</h6>
                                  </Col>
                              </Form.Group>
                          </Col>
                      : ''}
                      <Col className="col-12">
                          <Form.Group as={Row} controlId="formPlaintextCompanyName">
                              <Form.Label column className="col-12">
                                  <small> {t('price')}</small>
                              </Form.Label>
                              <Col className="col-12">
                                  <h6>{booking.order_items_sum_total_amount.toLocaleString(undefined, {minimumFractionDigits: 2})}&nbsp;{booking.site?.currency}</h6>
                              </Col>
                          </Form.Group>
                      </Col>
                  </Row>
                    {booking?.order_items?.map && (
                        <div className="mb-4 p-0">
                            <div
                                className="text-black font-weight-bold order-heading mb-2">{t('addons_services')}</div>
                            <div className="d-flex flex-wrap">
                                {
                                    booking.order_items?.map(orderItem => (
                                        <div className="font-weight-normal my-1" key={orderItem.id}>
                                            {
                                                orderItem.item_claimed_at ?
                                                    <div className="d-flex align-items-center mb-1"
                                                         key={orderItem.id}>
                                                        <img className="service-icon"
                                                             src={`/images/facilities/${orderItem.category_id}.svg`}/>
                                                        <span
                                                            className="mx-1 text-nowrap facility-name-site-card"><strike>{orderItem.name}</strike></span>
                                                    </div>
                                                    :
                                                    <div className="d-flex align-items-center mb-1"
                                                         key={orderItem.id}>
                                                        <img className="service-icon"
                                                             src={`/images/facilities/${orderItem.category_id}.svg`}/>
                                                        <span
                                                            className="mx-1 text-nowrap facility-name-site-card text-capitalize">{orderItem.name}</span>
                                                    </div>
                                            }
                                        </div>
                                    ))
                                }</div>
                        </div>
                    )}
                    {!booking.instant_order_token && booking.site && (
                        <div className="d-grid gap-2">
                            <a className="btn btn-primary"
                               href={`https://google.com/maps/dir/?api=1&destination=${booking.site.latitude},${booking.site.longitude}`}
                               target="_blank">{t('get_directions')}</a>
                        </div>
                    )}
                </div>
            </Card.Body>
        </div>
    )
}

export default Show;
